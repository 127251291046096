<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <h3 class="text-h5 mb-2">Roles</h3>
      <v-btn
          v-if="can($permissions['ROLES.UPSERT'])"
          color="primary"
          @click="openModal"
      >
        Добавить
      </v-btn>
    </div>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="max-width: 50px" class="text-left">
              №
            </th>
            <th>Keyword</th>
            <th>Name</th>
            <th v-if="ableToAction" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in list"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.keyword }}</td>
            <td>{{ item.name }}</td>
            <td v-if="ableToAction">
              <v-row justify="center">
                <v-icon
                    v-if="can($permissions['ROLES.UPSERT'])"
                    @click="getItem(item.id)"
                    class="cursor-pointer mr-2">mdi-pencil
                </v-icon>
                <v-icon
                    v-if="can($permissions['ROLES.TOGGLE'])"
                    @click="toggle(item.id)"
                    class="cursor-pointer">
                  {{ item.isDeleted ? 'mdi-toggle-switch-off-outline' : 'mdi-toggle-switch-outline' }}
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!--  Modals  -->
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Изменить Role</span>
          <span v-else class="headline">Добавить Role</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Keyword"
                    outlined
                    hide-details
                    v-model="item.keyword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Name"
                    outlined
                    hide-details
                    v-model="item.name"
                ></v-text-field>
              </v-col>
              <v-col v-if="update" cols="12">
                <v-select
                    v-model="selectedPermissions"
                    :items="permissionsList"
                    item-value="id"
                    item-text="keyword"
                    label="Permissions"
                    multiple
                    outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @click="selectToggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="permissionsList.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item disabled>
                      <v-list-item-content v-if="selectedAll">
                        <v-list-item-title>
                          Selected All
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content v-else>
                        <v-list-item-title>
                          Selected roles:
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ selectedPermissions.length }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      permissionsList: [],
      selectedPermissions: [],
      item: {
        id: 0,
        keyword: '',
        name: ''
      },
      dialog: false,
      update: false
    }
  },
  computed: {
    ableToAction() {
      return this.can(this.$permissions['ROLES.UPSERT']) ||
          this.can(this.$permissions['ROLES.TOGGLE'])
    },
    selectedAll () {
      return this.selectedPermissions.length === this.permissionsList.length
    },
    selectedSome () {
      return this.selectedPermissions.length > 0 && !this.selectedAll
    },
    icon () {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    async getList() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-authRole/getRoles');
        this.list = res.result;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getPermissions() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-authPermission/getPermissions');
        this.permissionsList = res.result
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getItem(id) {
      await this.getPermissions();
      this.update = true;
      try {
        const res = await this.$http.get(`/api-authRole/getRole?roleId=${id}`);
        this.item = res.result;
        this.selectedPermissions = this.item.permissionIds;
        this.dialog = true;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveItem() {
      try {
        const res = await this.$http.post('/api-authRole/upsertRole', this.item);
        this.list = res.result;
        await this.setPermissions();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async setPermissions() {
      try {
        const res = await this.$http.post(`/api-authRole/setPermissions?roleId=${this.item.id}`,
            this.selectedPermissions);
        this.dialog = false;
        // this.list = res.result;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggle(id) {
      try {
        const res = await this.$http.get(`/api-authRole/toggleActivation?roleId=${id}`);
        this.list = res.result;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.getPermissions();
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.item = {
        id: 0,
        keyword: '',
        name: ''
      }
      this.selectedPermissions = []
    },
    selectToggle () {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.selectedPermissions = []
        } else {
          this.selectedPermissions = [];
          this.permissionsList.map(x => {
            this.selectedPermissions.push(x.id);
          })
        }
        console.log(this.selectedPermissions);
      })
    },
  },
  watch: {},
  created() {
    this.getList();
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>